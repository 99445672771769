import { useMutation, useQuery, useQueryClient } from '@tanstack/vue-query'
import type { QueryOptions } from '~/src/types/QueryOptions'
import type { paths } from '~/src/api/schema'
import type { ClientUser } from '~/src/types/ClientUser'

export const GET_CLIENT_USER = '/api/client_users/{id}'
export const PATCH_CLIENT_USER = '/api/client_users'
export const SET_CURRENT_ORGANIZATION = '/api/client_users/current-organization'

export function getClientUser({ params, query }: QueryOptions<paths[typeof GET_CLIENT_USER]['get']>) {
  return useQuery({
    ...query,
    queryKey: [
      GET_CLIENT_USER,
    ],
    queryFn: async ({ signal }) => {
      const client = useApi()
      const { data } = await client.GET(GET_CLIENT_USER, {
        params,
        signal,
      })
      return data as ClientUser
    },
  })
}

export function patchClientUser() {
  return useMutation({
    mutationKey: [
      PATCH_CLIENT_USER,
    ],
    mutationFn: async ({ body }: { body: QueryOptions<paths[typeof PATCH_CLIENT_USER]['patch']>['body'] }) => {
      const client = useApi()
      const { data } = await client.PATCH(PATCH_CLIENT_USER, {
        body,
        headers: { 'Content-Type': 'application/merge-patch+json' },
      })
      return data
    },
  })
}

export function setCurrentOrganization() {
  const queryClient = useQueryClient()
  return useMutation({
    mutationKey: [
      SET_CURRENT_ORGANIZATION,
    ],
    mutationFn: async ({ body }: { body: QueryOptions<paths[typeof SET_CURRENT_ORGANIZATION]['patch']>['body'] }) => {
      const client = useApi()
      const { data } = await client.PATCH(SET_CURRENT_ORGANIZATION, {
        body,
        headers: { 'Content-Type': 'application/merge-patch+json' },
      })
      await queryClient.invalidateQueries({ queryKey: [GET_CLIENT_USER] })
      return data as ClientUser
    },
  })
}
